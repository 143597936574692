<template>
	<div v-if="showPrivacy" :class="privacyClass">
		<div :class="contentClass">
			<div class="title">隐私政策协议</div>
			<div class="des">
        尊敬的用户，
        为给你提供更好的服务，允许我们在必要场景下，合理使用你的个人信息，并充分保障你的合法权力。请你在使用前仔细查阅以下协议条款，点击同意即表示你已阅读并同意对应的协议内容并继续使用。
			</div>
      <div style="margin-top: 10px;">
        <van-checkbox :icon-size="14" v-model="radio" checked-color="#FB771E">
          <span style="font-size: 14px;">我已阅读并同意以下协议内容</span>
        </van-checkbox>
        <span class="link" @click="openPrivacyContract1">《用户协议》</span>
        <span class="link" @click="openPrivacyContract2">《隐私政策》</span>
        <span class="link" @click="openPrivacyContract3">《儿童隐私政策》</span>

      </div>
			<div class="btns">
				<button class="item reject" @click="exitMiniProgram">拒绝</button>
				<van-button
            id="agree-btn"
            type="button"
            class="item agree"
            :disabled="!radio"
					@click="handleAgreePrivacyAuthorization">同意</van-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:"xc-privacyPopup",
	data() {
		return {
			isRead: false,
			showPrivacy: false,
			privacyContractName: '',
      radio:false,
			resolvePrivacyAuthorization: null,
      id: '',
      schoolId:'',
		};
	},
	props: {
		position: {
			type: String,
			default: 'center'
		}
	},
	computed: {
		privacyClass() {
			return this.position === 'bottom' ? 'privacy privacy-bottom' : 'privacy';
		},
		contentClass() {
			return this.position === 'bottom' ? 'content content-bottom' : 'content';
		}
	},
  methods: {
    openPrivacyContract1() {
      this.$router.push(`/protocol/userAgreement`);
    },

    openPrivacyContract2() {
      this.$router.push(`/protocol/privacyAgreement`);
    },

    openPrivacyContract3() {
      this.$router.push(`/protocol/childrenSPrivacyAgreement`);
    },
    openPrivacyContract() {

    },
    exitMiniProgram() {
     this.showPrivacy = false;
    },
    handleAgreePrivacyAuthorization() {
      this.showPrivacy = false;
      this.$emit('allowPrivacy',this.id,this.schoolId);

    },
    closePrivacy(id,schoolid) {
      Object.assign(this.$data, this.$options.data())
      this.showPrivacy = true;
      this.id = id;
      this.schoolId = schoolid;
    },
    clickFn() {
      if (this.radio === '1') {
        this.radio = '0'
      }
    },
  }
}
</script>

<style scoped>
.privacy {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.privacy-bottom {
		align-items: flex-end;
	}

	.content {
		width: 85%;
		padding: 25px;
		box-sizing: border-box;
		background: #fff;
    background: url(../../assets/images/privacy/tc_bg2.png) no-repeat center center / cover;;
		border-radius: 16px;
	}

	.content-bottom {
		position: absolute;
		bottom: 0;
		width: 96%;
		padding: 36px;
		padding-bottom: env(safe-area-inset-bottom);
		border-radius: 16px 16px 0 0;
	}

	.content .title {
		text-align: center;
		color: #333;
		font-weight: bold;
		font-size: 16px;
	}

	.content .des {
		font-size: 15px;
		color: #666;
		margin-top: 20px;
		text-align: justify;
		line-height: 1.6;
	}

  .link {
		color: #FB771E;
    font-size: 12px;
	}

	.btns {
		margin-top: 24px;
		margin-bottom: 12px;
		display: flex;
	}

	.btns .item {
		width: 100px;
		height: 36px;
		overflow: visible;
		display: flex;
		align-items: center;

		justify-content: center;
		/* border-radius: 16rpx; */
		box-sizing: border-box;
		border: none !important;
	}

	.btns .reject {
		background: #f4f4f5;
		color: #1989ff;
		font-size: 14px;
		background: #edf5fe;
		font-weight: 300;
		margin-right: 48px;
	}

	.btns .agree {
		width: 100px;
		background: #1989ff;
		color: #fff;
		font-size: 16px;

	}

	.privacy-bottom .btns .agree {
		width: 440px;

	}
</style>
