<template>
  <div class="page-gray">
      <van-row>
        <van-col span="24">
          <div style="padding: 15px">
            <p style="font-size: 20px;font-weight: 600;">请选择学校登录</p>
            <span style="color: #666666">请选择您孩子对应的学校进入</span>
          </div>
        </van-col>
      </van-row>

    <div style="padding: 15px">
      <div style="max-height: 300px;overflow-y: auto;">
        <div style="margin: 15px 0;" v-for="(item,index) in studentArr" :key="index">
          <van-cell :title="item.school" is-link  @click="goSchool(item)"/>
        </div>
      </div>


      <div style="margin: 15px 0;">
        <van-cell icon="add" @click="addSchool()">
          <template slot="title">
            <span style="color:#666666">新增学校</span>
          </template>
        </van-cell>
      </div>

    </div>

    <xc-privacy-popup ref="privacyComponent" position="center" @allowPrivacy="allowPrivacy"/>

  </div>
</template>

<script>
import XcPrivacyPopup from "../../components/xc-privacyPopup/xc-privacyPopup.vue";
import Vue from "vue";
import {mapMutations} from "vuex";

export default {
  name: "selectSchool",
  components: {XcPrivacyPopup},
  data() {
    return {
      studentArr: [],
    };
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods:{
    ...mapMutations(['setStudentArr','setStudentInfo']),
    // 同意隐私协议
    allowPrivacy(id) {
      this.$router.push({path:'/',query:{id:id}})
      // 同意隐私协议触发事件，有些接口需要同意授权后才能执行，比如获取手机号授权接口，可以在同意隐私协议后，再执行授权获取手机号接口，如果不需要可以不添加该方法
    },
    //初始化学校信息
    init(){
      //获取学校信息
      this.$http.get(this.baseMallUrl + `/buyergrade/getStudentInfoByUserId/${Vue.prototype.userId}`).then(res => {
        this.studentArr = res.data;
        this.setStudentArr(this.studentArr);
      })
    },
    //进入学校
    goSchool(item){
      this.$refs.privacyComponent.closePrivacy(item.id,item.schoolid);
    },
    //添加学校
    addSchool(){
      this.$router.push({path: '/enterSchoolCode'});
    },
  }
}
</script>

<style scoped lang="less">

</style>
